@import url('https://fonts.googleapis.com/css?family=Raleway:300&display=swap');

.about-page {
    display: none;
}

header,
main,
footer,
section {
    background-color: white;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
}

header {
    padding: 12px;
    margin: 0px;
    box-shadow: 0px 0px 8px 0 grey;
}

.center-header {
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    max-width: 1000px;
}

.nav-links {
    display: 'inline';
}

body {
    margin: 0px;
}

h4 {
    text-align: centers;
    font-size: 24px;
    color: black;
    font-weight: 300;
}

p {
    font-size: 12px;
    font-weight: 300;
}

a {
    text-decoration: none;
    color: black;
}

.header-logo {
    float: left;
    height: 75px;
    margin: 0px;
}

.header-title,
.subheading {
    color: black;
}

.header-title {
    flex: 1;
    font-size: 23px;
    font-weight: 300;
    margin: 0px;
    margin-top: 5px;
    margin-left: 20px;
    vertical-align: bottom;
}

.header-links {
    /*flex: 1;*/
    margin-right: 30px;
    text-align: right;
}

.subheading {
    margin: 0px;
    font-size: 12px;
    padding-top: 0px;
    padding-bottom: 5px;
}

.flex-container {
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 94%;
}

.blocks {
    flex: 1;
    margin: 10px;
    box-shadow: 0px 2px 8px 0 grey;
    border-radius: 15px;
    background-color: white;
}

.project1,
.project2,
.project3 {
    margin-bottom: 18px;
}

.links {
    width: 100%;
    height: 100%;
}

.contact-form {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.input-field {
    width: 94%;
    height: 30px;
    margin-bottom: 10px;
    border-radius: 5px;
    border-style: solid;
    border-width: 0.5px;
    padding-left: 3%;
    padding-right: 3%;
    background-color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.message {
    height: 90px;
    padding-top: 12px;
    resize: none;
}

.submit {
    height: 30px;
    width: 100px;
    margin: 0 auto;
    display: block;
    border-radius: 5px;
    border-style: solid;
    border-width: 0.5px;
    border-color: black;
    box-shadow: 2px 2px 4px 0 lightGrey;
}

.footer-text {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 0px;
}

.footer-text-two {
    text-align: center;
    margin: 0px;
}

.footer-text-three {
    text-align: center;
    margin: 0px;
    padding-bottom: 40px;
}

@media screen and (min-width: 500px) {
    header {
        padding: 10px;
    }
    p {
        font-size: 15px;
    }
    .header-title {
        font-size: 30px;
    }
    .flex-container {
        width: 98%;
    }
    .project1,
    .project2,
    .project3 {
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 600px) {
    p {
        font-size: 18px;
    }
    .subheading {
        font-size: 15px;
    }
    .flex-container {
        flex-direction: column;
        max-width: 1000px;
    }
    .links {
        font-size: 11px;
    }
}

@media screen and (min-width: 800px) {
    .links {
        font-size: 16px;
    }
    .header-title {
        font-size: 40px;
    }
}

@media screen and (min-width: 1000px) {
    main {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .center-header {
        padding-left: 40px;
        padding-right: 40px;
    }
}
